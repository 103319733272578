import React from 'react'
import "./App.css"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'
import Web from './pages/Web'
import Mobile from './pages/Mobile'
import Contact from './pages/Contact'
import Chat from './components/Chat'

const App = () => (
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/web" element={<Web />} />
      <Route path="/mobile" element={<Mobile />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
    <Chat/>
    <Footer />
  </Router>
)

export default App
