import React from 'react'
import styled from 'styled-components'
import Card from '../components/Card'
import mobileAppImage from '../assets/images/mobile-app.jpg' // Replace with actual image path
import crossPlatformImage from '../assets/images/cross-platform.jpg' // Replace with actual image path

const MobilePageContainer = styled.div`
  text-align: center;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f4f4f4;
`

const Mobile = () => (
  // <MobilePageContainer>
  //   <Card
  //     image={mobileAppImage}
  //     title="Native Mobile Apps"
  //     description="We build high-quality, performant native apps for both iOS and Android platforms."
  //   />
  //   <Card
  //     image={crossPlatformImage}
  //     title="Cross-Platform Solutions"
  //     description="Our cross-platform solutions let you reach both iOS and Android users with a single codebase."
  //   />
  //   {/* Add more cards as needed */}
  <MobilePageContainer>
  <div class="custom-shape-divider-bottom-1732327412">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
    </svg>
  </div>
    <Card
      image={mobileAppImage}
      title="Native Mobile Apps"
      description="We build high-quality, performant native apps for both iOS and Android platforms."
    />
    <Card
      image={crossPlatformImage}
      title="Cross-Platform Solutions"
      description="Our cross-platform solutions let you reach both iOS and Android users with a single codebase."
    />
  </MobilePageContainer>

)

export default Mobile
