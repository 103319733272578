import styled from 'styled-components';

const PortfolioSection = styled.section`
  background: #f4f8fc;
  padding: 4rem 2rem;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const PortfolioTitle = styled.h2`
  font-size: 2rem;
  color: #005cbb;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;  // Stack project cards vertically on mobile
    gap: 1.5rem;
  }
`;

const ProjectCard = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: auto;
`;

const ProjectInfo = styled.div`
  padding: 1rem;
`;

const ProjectTitle = styled.h3`
  font-size: 1.2rem;
  color: #005cbb;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;


const Portfolio = () => (
  <PortfolioSection>
    <PortfolioTitle>Our Recent Work</PortfolioTitle>
    <PortfolioGrid>
      <ProjectCard>
        <ProjectImage src="project1.jpg" alt="Project 1" />
        <ProjectInfo>
          <ProjectTitle>Project Title 1</ProjectTitle>
          <ProjectDescription>Brief description of the project...</ProjectDescription>
        </ProjectInfo>
      </ProjectCard>
      <ProjectCard>
        <ProjectImage src="project2.jpg" alt="Project 2" />
        <ProjectInfo>
          <ProjectTitle>Project Title 2</ProjectTitle>
          <ProjectDescription>Brief description of the project...</ProjectDescription>
        </ProjectInfo>
      </ProjectCard>
      <ProjectCard>
        <ProjectImage src="project3.jpg" alt="Project 3" />
        <ProjectInfo>
          <ProjectTitle>Project Title 3</ProjectTitle>
          <ProjectDescription>Brief description of the project...</ProjectDescription>
        </ProjectInfo>
      </ProjectCard>
    </PortfolioGrid>
  </PortfolioSection>
);

export default Portfolio;
