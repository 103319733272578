import styled from 'styled-components';

const Footer = styled.footer`
  background: #e9eefa;
  color: #005cbb;
  padding: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const FooterLinks = styled.div`
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    display: block;  // Stack the footer links on small screens
    text-align: center;
  }
`;

const FooterLink = styled.a`
  color: #005cbb;;
  text-decoration: none;
  margin: 0 1rem;
  &:hover {
    color: #003366;
  }

  @media (max-width: 768px) {
    display: block;  // Make each link a block element on mobile
    margin: 0.5rem 0;
  }
`;


const FooterContent = () => (
  <Footer>
    <FooterLinks>
      <FooterLink href="#">Home</FooterLink>
      <FooterLink href="#services">Services</FooterLink>
      <FooterLink href="#about">About Us</FooterLink>
      <FooterLink href="#">Contact</FooterLink>
    </FooterLinks>
    <p>&copy; 2024 The Web Empire. All rights reserved.</p>
  </Footer>
);

export default FooterContent;
