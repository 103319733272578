import React from 'react'
import styled from 'styled-components'
// import Footer from './Footer';
import Hero from '../components/Hero';
import AnotherHero from '../components/AnotherHero';
import About from '../components/About';
import Portfolio from '../components/Portfolio';
import CTA from '../components/CTA';
import Services from '../components/Service';
import Industries from '../components/Industries';
import Years from '../components/Years';
import WebDevelopmentInfo from '../components/WebDevelopmentInfo';
import Header from '../components/Header';

// import Footer from '../components/Hero';




const HomePageContainer = styled.div`
  ${'' /* text-align: center; */}
  position: relative;
  ${'' /* width:100%; */}
  ${'' /* top: 0px; */}
  ${'' /* padding: 2rem; */}
  
`

const Home = () => (
  // <HomePageContainer>

  // <AnotherHero />
  // {/* <Hero /> */}
  // {/* <About /> */}

  // <WebDevelopmentInfo />
  // {/* <Years /> */}
  // {/* <Portfolio /> */}
  // <Industries />
  // <CTA />
  //   {/* <h1>Welcome to Our Solutions</h1>
  //   <p>
  //     We specialize in building custom web and mobile applications tailored to
  //     your needs.
  //   </p>
  //   <p>
  //     Explore our services to learn more about how we can help you achieve your
  //     goals.
  //   </p> */}
  <HomePageContainer>
    <div class="custom-shape-divider-bottom-1732327412">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
    </div>
    <Hero />
   <WebDevelopmentInfo />
   <Services />
   {/* <Portfolio />  */}
   <Industries />
   <CTA />

  </HomePageContainer>





)

export default Home
