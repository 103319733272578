import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

// Styled components for Hero Section
const HeroSection = styled.section`
  ${'' /* background: #f4f8fc; */}
  ${'' /* padding: 2rem 1rem; */}
  ${'' /* text-align: center; */}
  position: absolute;
  top: 0px;
  width:100%;

  @media (min-width: 768px) {
    padding: 1rem 1rem;
  }
  @media (min-width: 1024px) {
    padding: 1rem 5rem;
  }
`;


const HeroTitle = styled.h3`
  font-size: 1.5rem;
  font-family: 'Maven Pro';
  color: #005cbb;
  margin-bottom: 1rem;
  padding-left: 3rem;
  ${'' /* padding-top: 1.5rem; */}
  @media (min-width: 768px) {
    font-size: 2rem;
    margin-top: -100px;

  }
  @media (min-width: 1024px) {
    font-size: 3rem;
  }
  @media (max-width: 456px) {
    font-size: 1rem;
    padding-left: 1rem;
    margin-top: -60px;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  color: #333;
  opacity: 0.9;
  margin-bottom: 2rem;
  margin-top: -235px;
  padding-left: 3.2rem;
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: -220px;

  }
  @media (min-width: 1024px) {
    margin-top: -215px;
  }
  @media (max-width: 456px) {
    font-size: 0.8rem;
    padding-left: 1rem;
    margin-top: -115px;
  }
`;

const CTAButton = styled.a`
  padding: 0.75rem 1.5rem;
  background-color: #005cbb;
  color: white;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    background-color: #003f6b;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;


const PositionedImage = styled.img`
  ${'' /* position: ${(props) => props.position || 'absolute'}; */}
  top: ${(props) => props.top || 'auto'};
  left: ${(props) => props.left || 'auto'};
  margin-left: 40px;
  right: ${(props) => props.right || 'auto'};
  bottom: ${(props) => props.bottom || 'auto'};
  transform: ${(props) => props.transform || 'none'};
  width: ${(props) => props.width || 'auto'}; /* Default width */
  height: ${(props) => props.height || 'auto'}; /* Default height */
  z-index: ${(props) => props.zIndex || '1'}; /* Optional: default stacking order */
`;

const Hero = () => {
  
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Update window width on resize
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      // Attach event listener to handle window resize
      window.addEventListener('resize', handleResize);
  
      // Cleanup on unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });
  
    // Conditionally set width based on window width
    const elementWidth = windowWidth < 456 ? '220px' : '450px';
    return(
  <HeroSection>
    <div style={{ display: 'flex', alignItems: 'center'}}>
    <HeroTitle>Your Vision, <br></br>Our Innovation</HeroTitle>
    <PositionedImage 
        src="/home.png"
        alt="Example Image"
        // position="absolute"
        // top="60px"
        // right="240px"
        width={elementWidth}
        height={elementWidth}
        zIndex="2"
      />
      </div>
    <HeroSubtitle>Crafting Scalable Web <br></br>Applications</HeroSubtitle>
    {/* <CTAButton href="#contact">Get a Free Quote</CTAButton> */}
  </HeroSection>
  )
}
export default Hero;
