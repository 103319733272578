import styled from 'styled-components'

const AboutSection = styled.section`
  padding: 4rem 2rem;
  background: #ffffff;
  text-align: center;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #005cbb;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;  // Stack services vertically on mobile
    gap: 1.5rem;
  }
`;

const ServiceCard = styled.div`
  background: #f4f8fc;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  color: #005cbb;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;


const About = () => (
  <AboutSection id="about">
    <SectionTitle>What We Do</SectionTitle>
    <ServiceGrid>
      <ServiceCard>
        <ServiceTitle>Website Development</ServiceTitle>
        <ServiceDescription>Custom websites built to meet your business needs with a focus on design, performance, and SEO.</ServiceDescription>
      </ServiceCard>
      <ServiceCard>
        <ServiceTitle>Mobile App Development</ServiceTitle>
        <ServiceDescription>Native and cross-platform mobile apps to help your business reach users on the go.</ServiceDescription>
      </ServiceCard>
      <ServiceCard>
        <ServiceTitle>Digital Marketing</ServiceTitle>
        <ServiceDescription>Increase visibility with SEO, social media, and paid ad campaigns to drive business growth.</ServiceDescription>
      </ServiceCard>
    </ServiceGrid>
  </AboutSection>
);

export default About;
