import React, { useState, useEffect } from "react";
import styled, { keyframes }  from "styled-components";
import Counter from "./Counter";

// Container for the WebDevelopmentInfo with the slanted clip-path
const SlantedContainer = styled.div`
  position: relative;
  color: #005cbb;
  font-family: Arial, sans-serif;
  padding: 2rem 1rem; 
  ${'' /* min-height: 800px; */}
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden; /* Ensure the content doesn't spill out */
  clip-path: polygon(0 14%, 100% 0, 100% 84%, 0 100%);
  /* Apply the clip-path for slanted background */

  /* Pseudo-element for the background color */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #fff 50%, #fff 50%); /* Slanted background */
    z-index: -1; /* Keep the background behind the content */
  }

`;

// Title Styling for WebDevelopmentInfo
// const Title = styled.h1`
//   font-size: 2.5rem; /* Adjusted size for better fit */
//   font-weight: bold;
//   color: #fff;
//   span {
//     color: #f4f8fc;
//   }
// `;

// Description Styling
const Description = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 1rem;
  max-width: 800px; /* Ensure text width is limited */
  margin-left: auto;
  margin-right: auto;
`;

// Stats Container and Individual Stat Styling
const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  font-size: 1.2rem;
  width: 100%;
  max-width: 1000px; /* Max width for larger screens */
`;

const Stat = styled.div`
  text-align: center;
  h2 {
    margin: 0;
    font-size: 2rem;
    color: #4db6ac;
  }
  p {
    margin: 0.5rem 0 0;
    font-size: 1rem;
  }
`;

// Styled Component for Content
const ContentWrapper = styled.div`
  max-width: 800px;
  padding: 2rem;
  
  @media(max-width:456px) {
    padding-bottom: 4rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
`;

const Text = styled.p`
  font-size: 1.2rem;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  margin: 1rem 0;
  color: black;
  opacity: 0.8;
  line-height: 1.5;
  letter-spacing: 0.8px;
  @media(max-width: 456px) {
    font-size: 1rem;
  }
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
`;

// const Stat = styled.div`
//   text-align: center;
//   font-size: 1.5rem;
//   font-weight: 700;
// `;

const StatNumber = styled.div`
  font-size: 2.5rem;
  color: #005cbb;
  margin-bottom: 0.5rem;

  @media(max-width: 456px) {
    font-size: 1.5rem;
  }
`;

const StatLabel = styled.div`
  font-size: 1rem;
  color: black;
`;

const Brand = styled.span`
  ${'' /* font-size: 1rem; */}
  color: black;
  font-family: 'Maven Pro';
  white-space: nowrap;
  ${'' /* &::after {
  content: '|';
  animation: blink 0.8s infinite;
  } */}
  ${'' /* @keyframes blink {
    50% {
      opacity: 0;
    }
} */}
`;


const WebDevelopmentInfo = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 456);  // Set isMobile if width is 768px or less
    };
        // Event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Run once on mount
        handleResize();
        
        // Cleanup listener on unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  return (
    <SlantedContainer>
      <ContentWrapper>
        <Title>Welcome to <Brand>The Web Empire</Brand></Title>
        <Text>
          With years of expertise in delivering top-tier web and mobile solutions, we take pride in providing cutting-edge services to businesses worldwide. We have been crafting bespoke digital experiences since 2020, helping brands grow and thrive in the digital world.
        </Text>
        
        <Stats>
          <Stat>
            <StatNumber>4</StatNumber>
            <StatLabel>{isMobile ? 'YOE' : 'Years of Experience'}</StatLabel>
          </Stat>
          <Stat>
            <StatNumber>2020</StatNumber>
            <StatLabel>Founded</StatLabel>
          </Stat>
          <Stat>
            {/* <StatNumber>50+</StatNumber> */}
            <StatNumber>
              <Counter start={0} end={50} />
            </StatNumber>
            <StatLabel>Client Projects</StatLabel>
          </Stat>
        </Stats>
      </ContentWrapper>
    </SlantedContainer>
  );
};

export default WebDevelopmentInfo;
