import React from 'react';
import styled from 'styled-components';

const HeroSection = styled.section`
  background-image: url('/hero-image.png'); /* Replace with your image URL */
  background-size: cover;
  background-position: center center;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  position: relative; 
  margin-top:1px;
  /* For overlay effect */

  @media (max-width: 456px) {
    height: 60vh; /* Adjust height on smaller screens */
  }

  @media (min-width: 1024px) {
    height: 55vh;  /* Makes the hero section full height of the viewport */
    width: 100%; 
  }

`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 92, 187, 0.5); /* Semi-transparent blue overlay */
  z-index: 1; /* To make sure the overlay appears above the image */
`;

const HeroContent = styled.div`
  z-index: 2; /* Make sure the text appears above the overlay */
  max-width: 800px;
  padding: 2rem;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
const CTAButton = styled.a`
  padding: 0.75rem 1.5rem;
  background-color: #ffffff;
  color: #005cbb;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    background-color: #003f6b;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
`;

const Hero = () => (
  <HeroSection>
    <HeroOverlay />
    <HeroContent>
    <HeroTitle>Welcome to The Web Empire</HeroTitle>
    <HeroSubtitle>Your partner in innovative website and mobile solutions</HeroSubtitle>
    <CTAButton href="#contact">Get a Free Quote</CTAButton>
    </HeroContent>
  </HeroSection>
);

export default Hero;
