import styled from 'styled-components'
import { Link } from 'react-router-dom';

const CTASection = styled.section`
  background: #005cbb;
  color: white;
  padding: 3rem 2rem;
  text-align: center;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const CTATitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const CTAButton = styled.a`
  padding: 0.75rem 1.5rem;
  background-color: #ffffff;
  color: #005cbb;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    background-color: #003f6b;
    color: white;
  }

  @media (max-width: 768px) {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }
`;

const NavItem = styled(Link)`
  padding: 0.75rem 1.5rem;
  background-color: #ffffff;
  color: #005cbb;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    background-color: #003f6b;
    color: white;
  }

  @media (max-width: 768px) {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }

`;


const CTA = () => (
  <CTASection>
    <CTATitle>Ready to Take Your Business to the Next Level?</CTATitle>
    <NavItem to="/contact">Contact Us</NavItem>
  </CTASection>
);

export default CTA;
