import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';



// Header container with background and padding
const HeaderContainer = styled.header`
  background: #e9eefa;
  padding: 1rem;
  color: white;
  text-align: center;
  ${'' /* position: absolute;
  width: 76%;
  top: 0px; */}
`;

// Navigation container with flex display for horizontal alignment
const Nav = styled.nav`
  list-style: none;
  display: flex;  
  justify-content: center; 
  align-items: center;
  padding: 0;
  
`;

// Styled link for each navigation item
const NavItem = styled(Link)`
  color: #005cbb;
  margin: 0 1rem;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;  // Added transition for hover effect
  position: relative;

  &::after {
  content: '';               /* Empty content for the pseudo-element */
  position: absolute;        /* Position it relative to the link */
  bottom: -2px;              /* Position the line 2px below the text */
  left: 0;                   /* Align it to the left of the link */
  width: 100%;               /* Set initial width of the line */
  height: 2px;               /* Height of the line */
  background-color: #003f6b;   /* Line color */
  transform: scaleX(0);      /* Initially hide the line */
  transform-origin: bottom right; /* Makes it grow from the right */
  transition: transform 0.3s ease; /* Smooth transition */
}
  &:hover {
    color: #003f6b;  // Hover effect to change color when hovered over
  }
  &:hover::after {
  transform: scaleX(1); /* Reveal the line by scaling it to full width */
  transform-origin: bottom left; /* Make it grow from the left */
}

`;

// Styled link for the logo with bold font and larger size
const Logo = styled(Link)`
  color: #005cbb;
  margin: 0 1rem;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  transition: color 0.3s ease;
  &:hover {
    color: #003f6b;
  }
`;

const Header = () => (
  <HeaderContainer>
    <Nav>
      <Logo to="/">thewebempire</Logo>
      <NavItem to="/web">Web</NavItem>
      <NavItem to="/mobile">Mobile</NavItem>
    </Nav>
  </HeaderContainer>
);

export default Header;
