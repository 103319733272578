import React from 'react';
import styled from 'styled-components';
import Card from '../components/Card'

const IndustriesSection = styled.section`
  padding: 4rem 2rem;
  background-color: #ffffff;
  text-align: center;
`;

const IndustriesTitle = styled.h2`
  font-size: 2.5rem;
  color: #005cbb;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;  // Slightly smaller title on tablet and mobile
  }
`;

const IndustriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  ${'' /* gap: 2rem; */}
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;  // Stack cards vertically on smaller screens
  }
`;

const IndustryCard = styled.div`
  position: relative;
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  height: 220px;
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  letter-spacing: 1.1px;
  /* Set the background image */
  background: ${(props) => `url(${props.image}) no-repeat center center`};/* Replace this URL with your actual image */
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);

    border-radius: 8px;
    z-index: 1;
  }
  @media (max-width: 456px) {
    height: 180px;
  }
`;

// const IndustryIcon = styled.div`
//   font-size: 2rem;
//   color: #005cbb;
//   margin-bottom: 1rem;

//   @media (max-width: 768px) {
//     font-size: 1.5rem;  // Adjust icon size for smaller screens
//   }
// `;

const IndustryTitle = styled.h3`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    font-size: 1.25rem;  // Adjust title size for smaller screens
  }
`;

const IndustryDescription = styled.p`
  font-size: 1rem;
  color: #fff;
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    font-size: 0.9rem;  // Slightly smaller text for readability on small screens
  }
`;

const Industries = () => {
  return (
    <IndustriesSection id="industries">
      <IndustriesTitle>Industries We Serve</IndustriesTitle>
      <IndustriesGrid>
        {/* Healthcare */}
        {/* <IndustryCard>
          <IndustryIcon>🏥</IndustryIcon>
          <IndustryTitle>Healthcare</IndustryTitle>
          <IndustryDescription>
            Providing digital solutions for healthcare organizations, improving patient engagement and services.
          </IndustryDescription>
        </IndustryCard> */}

        {/* E-commerce */}
        {/* <IndustryCard>
          <IndustryIcon>🛒</IndustryIcon>
          <IndustryTitle>E-commerce</IndustryTitle>
          <IndustryDescription>
            Building scalable and secure online stores to enhance shopping experiences and drive sales.
          </IndustryDescription>
        </IndustryCard> */}

        <Card
          image="/shopping.jpg"
          title="Healthcare & E-commerce"
          description="Providing digital solutions for healthcare organizations and building scalable online stores to enhance shopping experiences and drive sales."
        />
        {/* <IndustryCard image="/shopping.jpg">
          <IndustryTitle>Healthcare & E-commerce</IndustryTitle>
          <IndustryDescription>
            Providing digital solutions for healthcare organizations and building scalable online stores to enhance shopping experiences and drive sales.
          </IndustryDescription>
        </IndustryCard> */}
        {/* Education */}
        {/* <IndustryCard>
          <IndustryIcon>🎓</IndustryIcon>
          <IndustryTitle>Education</IndustryTitle>
          <IndustryDescription>
            Creating engaging e-learning platforms and mobile apps for education providers.
          </IndustryDescription>
        </IndustryCard> */}

        {/* Finance */}
        {/* <IndustryCard>
          <IndustryIcon>💼</IndustryIcon>
          <IndustryTitle>Finance</IndustryTitle>
          <IndustryDescription>
            Developing secure mobile and web apps for financial institutions and fintech startups.
          </IndustryDescription>
        </IndustryCard> */}

        <Card
          image="/pupil.jpg"
          title="Education & Finance"
          description="Creating engaging e-learning platforms for education providers and developing secure mobile/web apps for financial institutions and fintech startups."
        />
        {/* <IndustryCard image="/pupil.jpg">
          <IndustryTitle>Education & Finance</IndustryTitle>
          <IndustryDescription>
            Creating engaging e-learning platforms for education providers and developing secure mobile/web apps for financial institutions and fintech startups.
          </IndustryDescription>
        </IndustryCard> */}

        {/* Startups */}
        <Card
          image="/startup.jpg"
          title="Startups"
          description="Helping startups develop user-friendly and efficient web & mobile applications to scale their business."
        />
        {/* <IndustryCard image="/startup.jpg">
          <IndustryTitle>Startups</IndustryTitle>
          <IndustryDescription>
            Helping startups develop user-friendly and efficient web & mobile applications to scale their business.
          </IndustryDescription>
        </IndustryCard> */}

        {/* Entertainment */}
        <Card
          image="/woman.jpg"
          title="Entertainment"
          description="Offering creative digital solutions for entertainment businesses to engage users with interactive content."
        />
        {/* <IndustryCard image="/woman.jpg">
          <IndustryTitle>Entertainment</IndustryTitle>
          <IndustryDescription>
            Offering creative digital solutions for entertainment businesses to engage users with interactive content.
          </IndustryDescription>
        </IndustryCard> */}


      </IndustriesGrid>
    </IndustriesSection>
  );
};

export default Industries;
