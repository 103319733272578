import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components for the chat button, modal, and form

const SectionTitle = styled.h2`
  ${'' /* font-size: 2.5rem; */}
  color: #005cbb;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    ${'' /* font-size: 2rem; */}
  }
`;
// Chat Button (Circular, bottom-right corner)
const ChatButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #005cbb;
  color: white;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;

  &:hover {
    background-color: #003d7a;
  }
`;

// Modal Background
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 9999;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  transition: opacity 0.3s;
`;

// Modal Container (small at bottom-right)
const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  width: 300px;
  ${'' /* height: 400px; */}
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

// Close Button for Modal
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
  &:hover {
    color: #005cbb;
  }
`;

// Form Elements inside Modal
const FormField = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  label {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 0.5rem;
    display: block;
  }

  input,
  textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #f9f9f9;

    &:focus {
      outline: none;
      border-color: #005cbb;
    }
  }

  textarea {
    resize: vertical;
    height: 120px;
  }
`;

const SubmitButton = styled.button`
  background-color: #005cbb;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  
  &:hover {
    background-color: #003d7a;
  }
`;

const ChatForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Open and Close Modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Message sent successfully!");
    toggleModal(); // Close modal after form submission
  };

  return (
    <div>
      {/* Chat Button */}
      <ChatButton onClick={toggleModal}>💬</ChatButton>

      {/* Modal Overlay */}
      <ModalOverlay show={isModalOpen}>
        <ModalContent>
          <CloseButton onClick={toggleModal}>×</CloseButton>

          <SectionTitle>Get in Touch</SectionTitle>
          <form onSubmit={handleSubmit}>
            {/* Name Field */}
            <FormField>
              <label htmlFor="name">Full Name</label>
              <input type="text" id="name" name="name" required />
            </FormField>

            {/* Email Field */}
            <FormField>
              <label htmlFor="email">Email Address</label>
              <input type="email" id="email" name="email" required />
            </FormField>

            {/* Message Field */}
            <FormField>
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" required />
            </FormField>

            {/* Submit Button */}
            <SubmitButton type="submit" >
              Send Message
            </SubmitButton>
          </form>
        </ModalContent>
      </ModalOverlay>
    </div>
  );
};

export default ChatForm;
