import React, { useState, useEffect } from 'react';
import styled from "styled-components";

const Counter = ({ start, end }) => {
  const [count, setCount] = useState(start); // Start the counter at 'start' value
  const [isRunning, setIsRunning] = useState(true); // Track if counter is running

  const StatNumber = styled.div`
  font-size: 2.5rem;
  color: #005cbb;
  margin-bottom: 0.5rem;
  @media(max-width: 456px) {
    font-size: 1.5rem;
  }
`;

  useEffect(() => {
    // Don't start counting if the counter has already reached the 'end' value
    if (count >= end) return;

    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount >= end) {
          clearInterval(intervalId); // Stop the counter when it reaches 'end'
          return end; // Make sure we don't exceed the 'end' value
        }
        return prevCount + 1; // Increment the counter
      });
    }, 50); // Update the count every 1 second

    // Cleanup: clear interval on component unmount or when count reaches 'end'
    return () => clearInterval(intervalId);
  }, [count, end]);

  return (
      <StatNumber>{count}+</StatNumber>
  );
};

export default Counter;
