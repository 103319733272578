import React from 'react'
import styled from 'styled-components'

const CardContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 300px;
  margin: 1rem;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

const CardImage = styled.img`
  width: 100%;
  height: 200px;;
`

const CardContent = styled.div`
  padding: 1rem;
`

const CardTitle = styled.h3`
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: #333;
`

const CardDescription = styled.p`
  color: #555;
  font-size: 1rem;
  line-height: 1.4;
`

const Card = ({ image, title, description }) => (
  <CardContainer>
    <CardImage src={image} alt={title} />
    <CardContent>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardContent>
  </CardContainer>
)

export default Card
