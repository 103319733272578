import React from 'react'
import styled from 'styled-components'
import Card from '../components/Card'
import webAppImage from '../assets/images/web-app.jpg' // Replace with actual image path
import apiImage from '../assets/images/api.jpg' // Replace with actual image path
import Industries from '../components/Industries'

const WebPageContainer = styled.div`
  ${'' /* text-align: center;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f4f4f4; */}
`

const Section = styled.section`
  padding: 4rem 2rem;
  background-color: #f4f7fc;
  text-align: center;
`;

const Title = styled.h2`
  color: #005cbb;
  margin-bottom: 2rem;
  font-size: 2.5rem;
`;

const Paragraph = styled.p`
  color: #333;
  font-size: 1.1rem;
  margin-bottom: 3rem;
`;

const WebDevelopment = () => {
  return (
    <Section>
      <Title>Our Web Development Services</Title>
      <Paragraph>
        We specialize in creating dynamic and visually appealing websites that are tailored to your business goals. Whether you're looking for a custom website, an e-commerce store, or a content management system (CMS), we have the experience to deliver.
      </Paragraph>
    </Section>
  );
};

// export default WebDevelopment;

const ExpertiseSection = styled.section`
  background-color: #f7f8fa;
  padding: 4rem 2rem;
  text-align: center;
`;

const ExpertiseTitle = styled.h2`
  color: #005cbb;
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const ExpertiseList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
`;

const ExpertiseItem = styled.li`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  text-align: center;

  h3 {
    color: #005cbb;
    margin-bottom: 1rem;
  }
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;

const WebExpertise = () => {
  return (
    <ExpertiseSection>
      <ExpertiseTitle>Our Expertise</ExpertiseTitle>
      <ExpertiseList>
        <ExpertiseItem>
          <h3>Custom Websites</h3>
          <p>Building responsive websites tailored to your brand and business goals.</p>
        </ExpertiseItem>
        <ExpertiseItem>
          <h3>E-commerce</h3>
          <p>Creating online stores with platforms like Shopify, WooCommerce, and Magento.</p>
        </ExpertiseItem>
        <ExpertiseItem>
          <h3>SEO Optimization</h3>
          <p>Improving search engine rankings and boosting organic traffic to your site.</p>
        </ExpertiseItem>
        <ExpertiseItem>
          <h3>Web Redesign</h3>
          <p>Revamping your website to keep it fresh, modern, and user-friendly.</p>
        </ExpertiseItem>
      </ExpertiseList>
    </ExpertiseSection>
  );
};

// export default WebExpertise;

const Web = () => (
  <WebPageContainer>
  <WebDevelopment />
    <Card
      image={webAppImage}
      title="Custom Web Development"
      description="We create responsive, high-performance websites tailored to your needs using the latest technologies."
    />
    <Card
      image={apiImage}
      title="API Development"
      description="Our team develops robust APIs that integrate seamlessly with your applications and services."
    />
    {/* Add more cards as needed */}
    <Industries/>
    <WebExpertise />
  </WebPageContainer>
)

export default Web
