import React from 'react';
import styled from 'styled-components';
import Card from '../components/Card'
import mobileAppImage from '../assets/images/mobile-app.jpg' // Replace with actual image path

const ServicesSection = styled.section`
  padding: 8rem 2rem 12rem 2rem;
  ${'' /* background-color: #f4f7fc; */}
  text-align: center;
  @media(max-width: 456px) {
    padding-top: 14rem;
    padding-bottom: 18rem;
  }
`;

const ServicesTitle = styled.h2`
  font-size: 2.5rem;
  color: #005cbb;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;  // Slightly smaller title on tablet and mobile
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  ${'' /* gap: 2rem; */}
  margin-top: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;  // Stack cards vertically on smaller screens
  }
`;

// const ServiceCard = styled.div`
//   background: #ffffff;
//   padding: 2rem;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   transition: transform 0.2s ease-in-out;
//   &:hover {
//     transform: scale(1.05);
//   }
// `;
const ServiceCard = styled.div`
  position: relative;
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  height: 220px;
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  letter-spacing: 1.2px;
  /* Set the background image */
  background: ${(props) => `url(${props.image}) no-repeat center center`};/* Replace this URL with your actual image */
  background-size: cover;
  background-position: center;

  /* Dark overlay to ensure text readability */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);

    border-radius: 8px;
    z-index: 1;
  }


  @media (max-width: 456px) {
    height: 180px;
  }


  &:hover {
    transform: scale(1.05);
  }
`;

const ServiceIcon = styled.div`
  font-size: 2rem;
  color: #fff;  /* Icon color changed to white for contrast */
  margin-bottom: 1rem;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 1.5rem;  // Adjust icon size for smaller screens
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  color: #fff;  /* Title color changed to white for better visibility */
  margin-bottom: 1rem;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 1.25rem;  // Adjust title size for smaller screens
  }
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  ${'' /* font-weight: 500; */}
  color: #fff;  /* Description color changed to white */
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 0.9rem;  // Slightly smaller text for readability on small screens
  }
`;
const SlantedContainer = styled.div`
  position: relative;
  color: #fff;
  font-family: Arial, sans-serif;
  ${'' /* padding: 8rem 2rem; */}
  ${'' /* min-height: 800px; */}
  ${'' /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */}
  overflow: hidden; /* Ensure the content doesn't spill out */
  clip-path: polygon(0 14%, 100% 0, 100% 84%, 0 100%);
  /* Apply the clip-path for slanted background */

  /* Pseudo-element for the background color */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #e9eefa 50%, #e9eefa 50%); /* Slanted background */
    z-index: -1; /* Keep the background behind the content */
  }
`;
const Services = () => {
  return (
    <SlantedContainer>
    <ServicesSection id="services">
      <ServicesTitle>Our Services</ServicesTitle>
      <ServicesGrid>
        {/* Website Development */}
        <Card
          image="/web-dev.jpg"
          title="Website Development"
          description="Custom websites, E-commerce platforms, SEO optimization, and redesigns."
        />
        {/* <ServiceCard image="/web-dev.jpg">
          <ServiceTitle>Website Development</ServiceTitle>
          <ServiceDescription>
            Custom websites, E-commerce platforms, SEO optimization, and redesigns.
          </ServiceDescription>
        </ServiceCard> */}

        {/* Mobile Development */}
        <Card
          image={mobileAppImage}
          title="Mobile Development"
          description="iOS/Android apps, Cross-platform solutions, and app maintenance & marketing."
        />
        {/* <ServiceCard image="/mobile-app.jpg">
          <ServiceTitle>Mobile Development</ServiceTitle>
          <ServiceDescription>
            iOS/Android apps, Cross-platform solutions, and app maintenance & marketing.
          </ServiceDescription>
        </ServiceCard> */}

        {/* Other Services */}
        {/* <ServiceCard image="/digital-marketing.jpg">
          <ServiceTitle>Other Services</ServiceTitle>
          <ServiceDescription>
            Web hosting, Digital marketing, Branding, and more to support your digital presence.
          </ServiceDescription>
        </ServiceCard> */}
        <Card
          image="/digital-marketing.jpg"
          title="Digital marketing"
          description="Web hosting, Digital marketing, Branding, and more to support your digital presence."
        />

        {/* UI/UX Design */}
        <Card
          image="/ui-ux.jpg"
          title="UI/UX Design"
          description="User-centered designs that enhance the user experience and interface for your websites and apps."
        />
      </ServicesGrid>
    </ServicesSection>
  </SlantedContainer>
  );
};

export default Services;
