import React from 'react';
import styled from 'styled-components';

const ContactUsPage = styled.section`
  padding: 4rem 2rem;
  background-color: #f9f9f9;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #005cbb;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HeroSection = styled.div`
  background-color: #005cbb;
  color: white;
  padding: 5rem 2rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HeroDescription = styled.p`
  font-size: 1.25rem;
  max-width: 700px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const FormSection = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormField = styled.div`
  margin-bottom: 1.5rem;

  label {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 0.5rem;
    display: block;
  }

  input, textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    background-color: #f9f9f9;

    &:focus {
      outline: none;
      border-color: #005cbb;
    }
  }

  textarea {
    resize: vertical;
    height: 150px;
  }
`;

const SubmitButton = styled.button`
  background-color: #005cbb;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #003d7a;
  }
`;

const ContactInfoSection = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const ContactInfoCard = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  text-align: center;

  h3 {
    font-size: 1.5rem;
    color: #005cbb;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: #333;
  }
`;

const MapSection = styled.div`
  margin-top: 4rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
`;

const MapIframe = styled.iframe`
  width: 100%;
  height: 400px;
  border: none;
  border-radius: 8px;
`;

const CTASection = styled.div`
  background-color: #005cbb;
  color: white;
  padding: 3rem 1.5rem;
  text-align: center;
  border-radius: 8px;
  margin-top: 4rem;

  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }

  button {
    background-color: #ffffff;
    color: #005cbb;
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

const ContactUs = () => {
  return (
    <ContactUsPage>
      {/* Hero Section */}
      <HeroSection>
        <HeroTitle>We’d love to hear from you!</HeroTitle>
        <HeroDescription>
          Whether you have a question, suggestion, or just want to say hello, feel free to reach out to us.
        </HeroDescription>
      </HeroSection>

      {/* Contact Form Section */}
      <FormSection id="contact">
        <SectionTitle>Get In Touch</SectionTitle>
        <form>
          <FormField>
            <label htmlFor="name">Full Name</label>
            <input type="text" id="name" name="name" required />
          </FormField>
          <FormField>
            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" name="email" required />
          </FormField>
          <FormField>
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" required />
          </FormField>
          <SubmitButton type="submit">Send Message</SubmitButton>
        </form>
      </FormSection>

      {/* Contact Information Section */}
      <ContactInfoSection>
        <ContactInfoCard>
          <h3>Email</h3>
          <p>hello@thewebempire.in</p>
        </ContactInfoCard>
        <ContactInfoCard>
          <h3>Phone</h3>
          <p>+123 456 7890</p>
        </ContactInfoCard>
        <ContactInfoCard>
          <h3>Office Address</h3>
          <p>1234 Business St, Suite 500, City, Country</p>
        </ContactInfoCard>
      </ContactInfoSection>

      {/* Map Section */}
      <MapSection>
        <SectionTitle>Find Us On The Map</SectionTitle>
        <MapIframe
          src="https://www.google.com/maps/embed/v1/place?q=New+York&key=YOUR_GOOGLE_MAPS_API_KEY"
          allowFullScreen
        />
      </MapSection>

      {/* Call to Action Section */}
      <CTASection>
        <h3>Have Any Questions?</h3>
        <p>We're here to help you. Get in touch with us today!</p>
        <button onClick={() => {
            // Scroll to the "contactSection"
            document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
          }}>Contact Us</button>
      </CTASection>
    </ContactUsPage>
  );
};

export default ContactUs;
