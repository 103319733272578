import React from 'react';
import styled from 'styled-components';

// Background Section with an angled gradient
const BackgroundSection = styled.section`
  position: relative;
  height: 60vh; /* Adjust based on your needs */
  background: linear-gradient(30deg, #005cbb, #e9eefa);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 2rem;
`;

// Styled Component for Content
const ContentWrapper = styled.div`
  max-width: 800px;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 10px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
`;

const Text = styled.p`
  font-size: 1.2rem;
  margin: 1rem 0;
  color: #fff;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
`;

const Stat = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
`;

const StatNumber = styled.div`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.div`
  font-size: 1rem;
  color: #e9eefa;
`;

const Hero = () => {
  return (
    <BackgroundSection>
      <ContentWrapper>
        <Title>Welcome to The Web Empire</Title>
        <Text>
          With years of expertise in delivering top-tier web and mobile solutions, we take pride in providing cutting-edge services to businesses worldwide. We have been crafting bespoke digital experiences since 2015, helping brands grow and thrive in the digital world.
        </Text>
        
        <Stats>
          <Stat>
            <StatNumber>9</StatNumber>
            <StatLabel>Years of Experience</StatLabel>
          </Stat>
          <Stat>
            <StatNumber>2015</StatNumber>
            <StatLabel>Founded</StatLabel>
          </Stat>
          <Stat>
            <StatNumber>100+</StatNumber>
            <StatLabel>Client Projects</StatLabel>
          </Stat>
        </Stats>
      </ContentWrapper>
    </BackgroundSection>
  );
};

export default Hero;
